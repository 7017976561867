import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { BadgeColor, BaseForm, FilterItem, IAction, PolicyDto, ReviewedByItemDto, SearchBody } from '@shared/classes';
import { ApprovedByItemDto } from '@shared/classes/model/backend/framework/model/approvedByItemDto';
import { ViewModeService } from '@shared/services/view-mode.service';
import { ResponsibilitiesDataService } from 'app/modules/entity-module/services/data/responsibilities-data.service';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { GroupDataService } from 'app/modules/users/services/data/group-data.service';
import { toSafeInteger } from 'lodash-es';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-policy-body-form',
  templateUrl: './policy-body-form.component.html',
  styleUrls: ['./policy-body-form.component.scss'],
})
export class PolicyBodyFormComponent extends BaseForm<PolicyDto> implements OnInit {
  @ViewChild('fileBrowser', { static: false }) fileBrowser: FileUpload;
  @Input() textEditorHeight: string = '500px';
  @Input() viewModeOnly: boolean = false;
  badgeColors = BadgeColor;
  submitButtonAction: IAction = {
    id: 1,
    label: 'Save & Close',
    buttonType: 'button',
    command: this.onSubmitForm.bind(this),
    icon: 'pi pi-save',
    loading$: this.loadingState$,
    // buttonStyle: 'outlined'
  };
  submitSaveButtonAction: IAction = {
    id: 3,
    label: 'Save',
    buttonType: 'button',
    command: this.onSubmitFormSave.bind(this),
    icon: 'pi pi-save',
    loading$: this.loadingState$,
  };
  disabledFormGroup: FormGroup = new FormGroup({
    creatorName: new FormControl({ value: null, disabled: true }),
    creationDate: new FormControl({ value: null, disabled: true }),
    approvedBy: new FormControl({ value: null, disabled: true }),
    reviewedBy: new FormControl({ value: null, disabled: true }),
  });
  responsibilityMap: { [x: string]: 'ONE' | 'ALL' } = {};
  approversMap: { [x: string]: 'ONE' | 'ALL' } = {};
  reviewersMap: { [x: string]: 'ONE' | 'ALL' } = {};
  linkedResponsibilities: { [x: string]: string[] } = {};
  approversProgress: number = 0;
  reviewersProgress: number = 0;
  rejectProgress: number = 0;
  seemsOkProgress: number = 0;
  reviewersTotal = 0;
  approversTotal = 0;
  @Input() defaultViewMode: boolean = true;
  showCancelFile = false;
  cancelFileSelectionAction: IAction = {
    id: 2,
    label: 'Cancel',
    buttonType: 'button',
    command: this.clearFile.bind(this),
    icon: 'pi pi-times',
  };

  approveData: any;
  reviewData: any;

  basicOptions: any;

  constructor(
    public viewModeService: ViewModeService,
    private respRequestService: ResponsibilitiesDataService,
    private groupRequestService: GroupDataService,
    private fileService: FilesDataService
  ) {
    super(viewModeService, 'POLICY_BODY');
  }

  ngOnInit(): void { }
  setupChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.approveData = {
      labels: ['Rejected', 'Approved', 'No Response'],
      datasets: [
        {
          data: [
            toSafeInteger((this.rejectProgress / this.approversTotal) * 100),
            toSafeInteger((this.approversProgress / this.approversTotal) * 100),
            toSafeInteger(
              ((this.approversTotal - this.rejectProgress - this.approversProgress) / this.approversTotal) * 100
            ),
          ],
          backgroundColor: [
            documentStyle.getPropertyValue('--red-500'),
            documentStyle.getPropertyValue('--green-500'),
            documentStyle.getPropertyValue('--gray-500'),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--red-400'),
            documentStyle.getPropertyValue('--green-400'),
            documentStyle.getPropertyValue('--gray-400'),
          ],
        },
      ],
    };

    this.reviewData = {
      labels: ['Comments Added', 'Seems Ok', 'No Response'],
      datasets: [
        {
          data: [
            toSafeInteger((this.reviewersProgress / this.reviewersTotal) * 100),
            toSafeInteger((this.seemsOkProgress / this.reviewersTotal) * 100),
            toSafeInteger(
              ((this.reviewersTotal - this.seemsOkProgress - this.reviewersProgress) / this.reviewersTotal) * 100
            ),
          ],
          backgroundColor: [
            documentStyle.getPropertyValue('--yellow-500'),
            documentStyle.getPropertyValue('--green-500'),
            documentStyle.getPropertyValue('--gray-500'),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--yellow-400'),
            documentStyle.getPropertyValue('--green-400'),
            documentStyle.getPropertyValue('--gray-400'),
          ],
        },
      ],
    };

    this.basicOptions = {
      borderColor: 'transparent',
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: textColor,
          },
        },
      },
    };
  }

  getData() {
    return this.getDataKeyValueFormat();
    // return this.viewModeService.viewMode == 'create' ? this.getDataKeyValueFormat() : this.getChangedFormValues().updateItems
  }

  setData(data: any) {
    this.resetData();

    this.formGroup.patchValue({ ...data?.policyBody, policy: data?.code });
    let bodyData = { ...data?.policyBody };
    bodyData.approvedBy = bodyData?.approvedBy ? bodyData?.approvedBy?.map((x) => x.user).join(', ') : '';
    bodyData.reviewedBy = bodyData?.reviewedBy ? bodyData?.reviewedBy?.map((x) => x.user).join(', ') : '';
    this.disabledFormGroup.patchValue(bodyData);
    this.data = data;
    if (this.data?.policyBody) {
      this.calcResponsibilityMap();
      this.getResponsibilitiesProgress();
    }
  }

  initFormStructure(): void {
    // this.disabledFormGroup = new FormGroup({
    //     creatorName: new FormControl({value:null,disabled:true}),
    //     creationDate: new FormControl({value:null,disabled:true}),
    //     approvedBy: new FormControl({value:null,disabled:true}),
    //     reviewedBy: new FormControl({value:null,disabled:true}),
    // });
    this.formGroup = new FormGroup({
      body: new FormControl(null),
      label: new FormControl(null),
      versionName: new FormControl(null),
      policy: new FormControl(null),
      documentUrl: new FormControl(null),
    });

    setTimeout(() => { }, 100);
  }
  getResponsibilitiesProgress() {
    let entitySearchBody: SearchBody = {
      projectionFields: ['name', 'code', 'members'],
      filters: [],
    };
    let entityFilter: FilterItem = {
      property: 'code',
      operation: 'IN',
      value: Object.keys(this.responsibilityMap),
    };
    let entityBody = { ...entitySearchBody, filters: [entityFilter] };
    //@TODO: use group service instead
    this.groupRequestService.getGroupsMembers([...Object.keys(this.responsibilityMap)]).subscribe((res) => {
      this.linkedResponsibilities = res;
      this.calcProgress();
    });
    // this.respRequestService.search(
    //     { all: true, page: 0 },
    //     entityBody,
    //     { showLoading: false, showMsg: false }
    // ).subscribe({
    //     next: (res: any) => {
    //         this.linkedResponsibilities = res;
    //         this.calcProgress();
    //     }
    // })
  }
  calcResponsibilityMap() {
    this.responsibilityMap = {};
    this.approversMap = {};
    this.reviewersMap = {};

    (this.data as PolicyDto)?.approvers?.forEach((element) => {
      this.approversMap[element.name] = element.mode;
      this.responsibilityMap[element.name] = element.mode;
    });
    (this.data as PolicyDto)?.reviewers?.forEach((element) => {
      this.reviewersMap[element.name] = element.mode;
      this.responsibilityMap[element.name] = element.mode;
    });
  }
  calcProgress() {
    let approversPeopleMap = {};

    let rejectProg = 0;
    let approversProg = 0;

    let reviewersPeopleMap = {};

    let noCommentProg = 0;
    let seemsOkProg = 0;

    Object.keys(this.linkedResponsibilities).forEach((key, index) => {
      const resp = this.linkedResponsibilities[key];
      if (this.approversMap[key]) {
        if (this.approversMap[key] == 'ALL') {
          resp?.forEach((user) => {
            let approved = (this.data as PolicyDto)?.policyBody?.approvedBy?.find((x) => x.user == user);
            approversPeopleMap[user] = approved
              ? { res: true, mode: approved.approveMode }
              : { res: false, mode: null };
          });
        } else if (this.approversMap[key] == 'ONE') {
          let found = false;
          for (let i = 0; i < resp?.length; i++) {
            const user = resp?.[i];
            let approved = (this.data as PolicyDto)?.policyBody?.approvedBy?.find((x) => x.user == user);

            if (approved ? true : false) {
              approversPeopleMap[user] = { res: true, mode: approved.approveMode };
              found = true;
              break;
            }
          }
          if (!found) {
            if (resp?.length > 0) {
              approversPeopleMap[resp?.[0]] = { res: false, mode: null };
            } else {
              approversPeopleMap[index] = { res: false, mode: null };
            }
          }
        }
      }
      if (this.reviewersMap[key]) {
        if (this.reviewersMap[key] == 'ALL') {
          resp?.forEach((user) => {
            let reviewed = (this.data as PolicyDto)?.policyBody?.reviewedBy?.find((x) => x.user == user);
            reviewersPeopleMap[user] = reviewed ? { res: true, mode: reviewed.reviewMode } : { res: false, mode: null };
          });
        } else if (this.reviewersMap[key] == 'ONE') {
          let found = false;
          for (let i = 0; i < resp?.length; i++) {
            const user = resp?.[i];
            let reviewed = (this.data as PolicyDto)?.policyBody?.reviewedBy?.find((x) => x.user == user);
            if (reviewed ? true : false) {
              reviewersPeopleMap[user] = { res: true, mode: reviewed.reviewMode };
              found = true;
              break;
            }
          }
          if (!found) {
            if (resp?.length > 0) {
              reviewersPeopleMap[resp?.[0]] = { res: false, mode: null };
            } else {
              reviewersPeopleMap[index] = { res: false, mode: null };
            }
          }
        }
      }
    });

    Object.keys(approversPeopleMap).forEach((key) => {
      this.approversTotal++;
      approversProg +=
        approversPeopleMap[key] &&
          approversPeopleMap[key].mode &&
          approversPeopleMap[key].mode == ApprovedByItemDto.ApproveModeEnum.Approve
          ? 1
          : 0;
      rejectProg +=
        approversPeopleMap[key] &&
          approversPeopleMap[key].mode &&
          approversPeopleMap[key].mode == ApprovedByItemDto.ApproveModeEnum.Reject
          ? 1
          : 0;
    });
    Object.keys(reviewersPeopleMap).forEach((key) => {
      this.reviewersTotal++;
      noCommentProg +=
        reviewersPeopleMap[key] &&
          reviewersPeopleMap[key].mode &&
          reviewersPeopleMap[key].mode == ReviewedByItemDto.ReviewModeEnum.CommentsAdded
          ? 1
          : 0;
      seemsOkProg +=
        reviewersPeopleMap[key] &&
          reviewersPeopleMap[key].mode &&
          reviewersPeopleMap[key].mode == ReviewedByItemDto.ReviewModeEnum.SeemsOk
          ? 1
          : 0;
    });

    this.reviewersProgress = noCommentProg;
    this.seemsOkProgress = seemsOkProg;

    this.approversProgress = approversProg;
    this.rejectProgress = rejectProg;

    this.setupChartData();
  }
  onBasicUpload(event) {
    for (let file of event.files) {
      this.fileService.uploadFile(file, { description: file.name, versionName: file.name }).subscribe((res) => {
        this.formGroup.patchValue({
          documentUrl: `${environment.websiteUrl}/file-manager/documents/download/${res.fileId}`,
        });
        this.clearFile(this.fileBrowser);
      });
    }
  }
  onSelectFile() {
    this.showCancelFile = true;
  }
  clearFile(fileBrowser) {
    fileBrowser?.clear();
    this.showCancelFile = false;
  }
  resetData() {
    this.responsibilityMap = {};
    this.approversMap = {};
    this.reviewersMap = {};
    this.linkedResponsibilities = {};
    this.approversProgress = 0;
    this.reviewersProgress = 0;
    this.rejectProgress = 0;
    this.seemsOkProgress = 0;
    this.reviewersTotal = 0;
    this.approversTotal = 0;
    this.approveData = null;
    this.reviewData = null;
  }
}
